/* Refactor this file later */
.document-pane-field-wrapper {
    width: 100%;
    margin-top: 20px;
}

.document-pane-stack {
    display: flex;
    width: 100%;
    margin-top: 20px;
    justify-content: space-between;
    gap: 10px;
}

.document-pane-stack-item{
    width: 100%;
    padding-top: 5px;
}